import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '../../components/Button/Button';
import FormServerError from '../../components/Form/FormServerError';
import { useTranslationByKey } from '../../hooks/use-translation-by-key';
import { Formik, Form } from 'formik';
import AuthPageLayout from '../../components/PageLayout/AuthPageLayout';

const ConfirmResetPage: React.FC = () => {
  const t = useTranslationByKey('CONTAINERS.CONFIRM_RESET');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const confirmationUrl = queryParams.get('confirmation_url');

  const [errorResponse, setErrorResponse] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    setErrorResponse(null);
  }, [confirmationUrl]);

  const handleConfirm = () => {
    if (confirmationUrl) {
      setIsSubmitting(true);
      window.location.href = confirmationUrl;
    } else {
      setErrorResponse(t('ERROR_INVALID_LINK'));
    }
  };

  return (
    <AuthPageLayout>
      <Formik initialValues={{}} onSubmit={() => handleConfirm()}>
        <Form className="form reset-password-form">
          <h1>{t('TEXT_CONFIRM_RESET')}</h1>

          {!confirmationUrl && <FormServerError errorMessage={t('ERROR_INVALID_LINK')} />}

          {confirmationUrl && (
            <div className="auth-form-message">
              <p>{t('TEXT_CLICK_BUTTON_BELOW')}</p>
            </div>
          )}

          <div className="form-buttons">
            <Button color="blue-gray" isBlock={true} isLoading={isSubmitting} type="submit">
              {t('BUTTON_CONFIRM_RESET')}
            </Button>
          </div>
        </Form>
      </Formik>
    </AuthPageLayout>
  );
};

export default ConfirmResetPage;
