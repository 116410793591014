import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import './App.scss';
import { AppContextProvider } from './AppContextProvider';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import PublicRoute from './components/PublicRoute/PublicRoute';
import { useTranslationByKey } from './hooks/use-translation-by-key';
import DashboardPage from './pages/Dashboard/Dashboard';
import LoginPage from './pages/Login/Login';
import ResetPasswordPage from './pages/ResetPassword/ResetPassword';
import PasswordResetConfirmPage from './containers/PasswordResetConfirm/PasswordResetConfirmPage';
import { useSupabaseUser } from './useSupabaseUser';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmResetPage from './containers/ConfirmResetPage/ConfirmResetPage';

interface Props {
  history: History;
}

function App(props: Props): ReactElement {
  const t = useTranslationByKey('APP');
  const { i18n } = useTranslation();
  const user = useSupabaseUser();
  const isAuthenticated = !!user;

  useEffect(() => {
    const language = localStorage.getItem('language');
    const languages = i18n.languages;

    if (languages.includes(language)) {
      i18n.changeLanguage(language);
    }
  }, []);

  return (
    <div className="app">
      <AppContextProvider>
        <ConnectedRouter history={props.history}>
          <Switch>
            <Route path="/dashboard">
              <PrivateRoute component={DashboardPage} title={t('TEXT_DASHBOARD')} />
            </Route>

            <Route path="/login">
              {isAuthenticated ? (
                <Redirect to="/dashboard" />
              ) : (
                <PublicRoute component={LoginPage} title={t('TEXT_LOGIN')} />
              )}
            </Route>

            <Route path="/reset-password">
              <PublicRoute component={ResetPasswordPage} title={t('TEXT_RESET_PASSWORD')} />
            </Route>

            <Route path="/password-reset-confirm">
              <PrivateRoute component={PasswordResetConfirmPage} title={t('TEXT_RESET_PASSWORD')} />
            </Route>

            <Route path="/confirm-reset">
              <PublicRoute component={ConfirmResetPage} title={t('TEXT_RESET_PASSWORD')} />
            </Route>

            {/* Keep last in the list */}
            <Route path="/">
              {isAuthenticated ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}
            </Route>
          </Switch>
        </ConnectedRouter>
        <ToastContainer />
      </AppContextProvider>
    </div>
  );
}

export default App;
